@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Inter-normal-300.woff) format("woff");
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Inter-normal-400.woff) format("woff");
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Inter-normal-500.woff) format("woff");
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Inter-normal-600.woff) format("woff");
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Inter-normal-700.woff) format("woff");
	unicode-range: U+0-10FFFF;
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Poppins-normal-400.woff) format("woff");
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Poppins-normal-500.woff) format("woff");
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Poppins-normal-600.woff) format("woff");
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-display: auto;
	font-stretch: normal;
	src: url(../public/fonts/Poppins-normal-700.woff) format("woff");
	unicode-range: U+0-10FFFF;
}
